* {
  margin: 0px;
  padding: 0px;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  box-sizing: border-box;
}

body {
  padding: 0px !important;
}

/* Input */
.double-input {
  display: flex;
  width: 100%;

  & > :first-child {
      margin-right: 10px;
  }

}

@media screen and (max-width: 420px) {

  .double-input {
      flex-direction: column;

      & > :first-child {
        margin-right: 0px;
      }

  }

}

/* Utils */

.line-break {
  width: 100%;
  height: 1px;
  background-color: #CCCCCC;
}

@media screen and (max-width: 577px) {
  .container {
    width: 95% !important;
  }
}

.form-floating>.form-control:not(:placeholder-shown)~label::after {
  background-color: transparent !important;
}

/* Scroll */
/* Largura do scroll */
::-webkit-scrollbar {
  width: 10px;
}

/* Cor do fundo do scroll */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Cor da thumb (barra) do scroll */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Hover na thumb do scroll */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}